import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@libs/app.services/api.service';
import { IUser, IUserDocs, USER_DOCS_READ_STATE } from '@libs/interfaces';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';

@Injectable({ providedIn: 'root' })
export class UsersDocsService {
  constructor(private api: ApiService) {}

  updateUserDocsState(data: { user_id: string; docs: IUserDocs }): Observable<IUser> {
    return this.api.patch('user/docs', data);
  }
  fetchUserDocs(user_id: string): Observable<IUserDocs> {
    return this.api.get(`fetch-user-docs/${user_id}`).pipe(MapResponseData);
  }
  removeDoc(data): Observable<boolean> {
    return this.api.post('remove-doc', data);
  }
  setUserDocsReadedState(data: { user_id: string; state: USER_DOCS_READ_STATE }) {
    return this.api.post('user/docs/state', data);
  }
  fetchUserDocsPendingRecentlyUploaded() {
    return this.api.get('users/docs/pending/recently-uploaded').pipe(MapResponseData);
  }
}
